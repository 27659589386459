<template>
  <v-row align="center" justify="center">
    <svg-top-gradient/>
    <v-col cols="12" md="10">
      <v-row align="center" justify="center">
        <v-col cols="10" md="6" v-if="!loaded">
          <v-skeleton-loader
              type="list-item-two-line"/>
        </v-col>
        <v-col cols="10" md="6" v-if="!loaded">
          <v-skeleton-loader
              type="image"/>
        </v-col>
        <v-col cols="10" md="6" v-if="loaded">
          <head-error-info :travel="travel" :resref="reservation.ref" :payment-o-k="false" :print="printWindow"/>
        </v-col>
        <v-col cols="10" md="6" v-if="loaded">
          <v-img height="400" aspect-ratio="1"
                 :src="this.image_url+travel.image"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="10">
      <v-row align="top" justify="center">
        <v-col cols="12" md="6">
          <h3 class="reservationTitle" v-text="$ml.get('confirmation.errortitle')" />
          <v-row>
            <v-col cols="12" md="12" class="infoTravel"  v-text="$ml.get('confirmation.errormessage')" />

            <v-col cols="6" class="pa-10">
              <form ref="redsys_form" action="https://sis.redsys.es/sis/realizarPago" method="post"
                    id="redsys_form" name="redsys_form">
                <input type="text" name="Ds_SignatureVersion" v-model="Ds_SignatureVersion" hidden style="display: none"/>
                <input type="text" name="Ds_MerchantParameters" v-model="Ds_MerchantParameters" hidden
                       style="display: none"/>
                <input type="text" name="Ds_Signature" v-model="Ds_Signature" hidden style="display: none"/>
                <v-btn
                    align="start"
                    class="ma-2 justify-start payBT"
                    :loading="loading"
                    color="#d81d86"
                    dark
                    @click="payOrder" v-text="$ml.get('travel.form.pay')">

                  <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                  </template>
                </v-btn>
              </form>
            </v-col>
            <v-col cols="6" class="pa-10">
              <v-btn outlined color="#d81d86" @click="deleteReservation">Cancelar reserva</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <reservation-info :typus="travel.typus" :reservation="reservation" :seats="seats" :files="files"
                          :passengers="passengers"/>
        <files-info :files="files"/>
        <contact-info/>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SvgTopGradient from "../components/partials/svgTopGradient";
import ReservationInfo from "../components/confirmation/reservationInfo";
import FilesInfo from "../components/confirmation/filesInfo";
import ContactInfo from "../components/confirmation/contactInfo";
import HeadErrorInfo from "../components/confirmation/headErrorConfirmation";

export default {
  name: "Confirmation",
  components: {
    HeadErrorInfo,
    ContactInfo,
    FilesInfo,
    ReservationInfo, SvgTopGradient,
  },
  data() {
    return {
      loading: false,
      travel: [],
      reservation: [],
      passengers: [],
      seats: [],
      files: [],
      loaded: false,
      paymentData: {
        DS_MERCHANT_AMOUNT: 50,
        DS_MERCHANT_CURRENCY: "978",
        DS_MERCHANT_MERCHANTCODE: "337481998",
        DS_MERCHANT_MERCHANTURL: "http://www.prueba.com/urlNotificacion.php",
        DS_MERCHANT_ORDER: '',
        DS_MERCHANT_TERMINAL: "3",
        DS_MERCHANT_TRANSACTIONTYPE: "0",
        DS_MERCHANT_URLKO: this.env_url+'/viaje/' + this.$route.params.ref,
        DS_MERCHANT_URLOK: ''
      },
      Ds_MerchantParameters: null,
      Ds_Signature: null,
      Ds_SignatureVersion: null,
    }
  },
  mounted() {
    this.getData();

  },
  methods: {
    getData() {
      let url = this.base_url + "/client/errorpago/" + this.$route.params.ref + "/" + this.$route.params.idc
      this.axios.get(url).then(response => {
        this.travel = response.data.travel;
        this.reservation = response.data.reservation;
        this.passengers = response.data.passengers;
        this.seats = response.data.seats;
        this.files = response.data.files;
        this.loaded = true;
        this.$ma.trackEvent({
          category: 'paymenterror',
          action: 'payment error data',
          properties: {travel:this.$route.params.ref, rid: this.$route.params.idc,version:this.version}
        });
      }).catch(e=>{
        if(e.response.status === 404)this.$router.push('/')
      });
    },
    payOrder() {
      const cart = this.passengers[0].nif;
      const key = Date.now().toString();
      this.paymentData.DS_MERCHANT_ORDER = this.CryptoJS.AES.encrypt(cart, key).toString().substr(33, 10);
      this.paymentData.DS_MERCHANT_AMOUNT = ""+parseInt(this.reservation.total,10)
      let formData = new FormData();
      formData.set('idr', this.$route.params.idc)
      formData.set('redsys', this.paymentData.DS_MERCHANT_ORDER)
      let url = this.base_url + 'client/actualizarredsis'
      this.axios.post(url, formData).then(() => {
        this.$ma.identify({userId: this.$route.params.idc, options: {newUser: true}});
        this.$ma.setUserProperties({userId: this.$route.params.idc, redsiscode:this.paymentData.DS_MERCHANT_ORDER })
        this.paymentData.DS_MERCHANT_AMOUNT = this.total;
        if (this.$ml.current === 'Esp')
          this.paymentData.DS_MERCHANT_MERCHANTURL = "https://kariba.autocarsgamon.com/API/client/TPVNotification.php?lang=1";
        else
          this.paymentData.DS_MERCHANT_MERCHANTURL = "https://kariba.autocarsgamon.com/API/client/TPVNotification.php?lang=2";
        this.paymentData.DS_MERCHANT_URLOK = this.env_url + '/confirmacion/' + this.$route.params.ref + '/' +this.$route.params.idc;
        this.paymentData.DS_MERCHANT_URLKO = this.env_url + '/errorenpago/' + this.$route.params.ref + '/' +this.$route.params.idc;
        this.$ma.trackEvent({
          category: 'Travel',
          action: 'Reservo desde error pago OK',
          properties: {
            travel: this.$route.params.ref,
            rid: this.$route.params.idc,
            merchanturl: this.paymentData.DS_MERCHANT_MERCHANTURL,
            merchantOK: this.paymentData.DS_MERCHANT_URLOK,
            merchantKO: this.paymentData.DS_MERCHANT_URLKO,
            version: this.version
          }
        });
        this.checkPayment()
      });
    },
    async checkPayment() {
      this.$ma.trackEvent({
        category: 'paymenterror',
        action: 'go to TPV from paymenterror',
        properties: {travel:this.$route.params.ref, rid: this.$route.params.idc,version:this.version}
      });
      let encodeData = await this.encodePayment()
      if (encodeData) this.$refs.redsys_form.submit()
    },

    async encodePayment() {
      try {
        this.priceForRedsys()
        let merchant = this.$CryptoJS.enc.Utf8.parse(JSON.stringify(this.paymentData))
        this.Ds_MerchantParameters = merchant.toString(this.$CryptoJS.enc.Base64)
        let key = this.$CryptoJS.enc.Base64.parse('k5t/2miwt2/WbHLB4kwT0zUtMDG+smzJ')
        let iv = this.$CryptoJS.enc.Hex.parse("0000000000000000");
        let cipher = this.$CryptoJS.TripleDES.encrypt(this.paymentData.DS_MERCHANT_ORDER, key, {
          iv: iv,
          mode: this.$CryptoJS.mode.CBC,
          padding: this.$CryptoJS.pad.ZeroPadding // Redsys dont like padding
        })
        let signature = this.$CryptoJS.HmacSHA256(this.Ds_MerchantParameters, cipher.ciphertext);
        this.Ds_Signature = signature.toString(this.$CryptoJS.enc.Base64)
        this.Ds_SignatureVersion = "HMAC_SHA256_V1"
        return true
      } catch (error) {
        return false
      }
    },
    priceForRedsys() {
      let priceN = parseFloat(this.reservation.total)
      if (Number.isInteger(priceN)) {
        this.paymentData.DS_MERCHANT_AMOUNT = priceN.toString() + '00'
      } else {
        let price_fixed = priceN.toFixed(2).toString()
        let price_string = price_fixed.replace('.', '')
        this.paymentData.DS_MERCHANT_AMOUNT = price_string
      }
    },
    deleteReservation(){
        let url = this.base_url + "/client/borrarreserva/" + this.$route.params.idc
        this.axios.get(url).then(() => {
          this.$ma.trackEvent({
            category: 'paymenterror',
            action: 'cancel reservation',
            properties: {travel:this.$route.params.ref, rid: this.$route.params.idc,version:this.version}
          });
          this.$router.push('/')
        });
    },
    printWindow: function () {
      window.print();
    }
  }
}
</script>
<style scoped>
.reservationTitle {
  color: #d81d86;
  font-weight: bold;
  font-size: 18pt;
}


</style>

