<template>
  <v-card height="450" class="pa-10">
    <v-card-title class="text-center justify-center" style="display: contents">
      <h1 class="titleTravel text-center" v-text="$ml.get('confirmation.errortitle')" />
      <h3 class="subTitleTravel text-center" v-if="$ml.current === 'Esp'">{{ travel.titleES }}</h3>
      <h3 class="subTitleTravel text-center" v-else>{{ travel.titleCAT }}</h3>
    </v-card-title>
    <v-card-text align="center">
      <v-simple-table>
        <tbody>
        <tr>
          <td>
            <div class="my-4 subtitle-1">
              <i class="far fa-calendar-alt"/> <b v-text="$ml.get('confirmation.date')" /><br> {{ date }}
            </div>
          </td>
          <td>
            <div class="my-4 subtitle-1" v-if=" travel.duration==='1'">
              <i class="far fa-clock"/>  <b v-text="$ml.get('confirmation.duration')" /><br> {{  travel.duration }} día
            </div>
            <div class="my-4 subtitle-1" v-else>
              <i class="far fa-clock"/><b v-text="$ml.get('confirmation.duration')" /><br>   {{  travel.duration }} días
            </div>
          </td>
        </tr>
        <tr  v-if="paymentOK">
          <td>
            <div class="my-2 subtitle-1">
              <i class="fas fa-wallet"/> <b v-text="$ml.get('confirmation.price')" /><br> {{ travel.price }}€

            </div>
            <v-btn class="ma-2 no-print" outlined color="#b52787" @click="print" v-text="$ml.get('confirmation.print')" />

          </td>
          <td>
            <div class="mt-2 subtitle-1">
              <i class="fas fa-bus"/> <b v-text="$ml.get('confirmation.code')" /><br> {{resref}}
            </div>
            <vue-qrcode :value="resref" :scale="3" />
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: "headErrorInfo",
  components:{
    VueQrcode,
  },
  data(){
    return{
      quantity:0,
      date:''
    }
  },
  props:['travel','resref','print','paymentOK'],
  mounted() {
    this.quantity = (parseInt(this.travel.places)-parseInt(this.travel.reservations))
    const splitDate = this.travel.date.split('-');
    this.date = splitDate[2]+'-'+splitDate[1]+'-'+splitDate[0]
  }
}
</script>

<style scoped>
.titleTravel {
  font-size: 24pt;
  font-weight: bold;
  color: #d81d86;
  word-break: keep-all;
}
.subTitleTravel {
  font-size: 18pt;
  font-weight: bold;
  color: #d81d86;
  word-break: keep-all;
}
</style>